.profile_page {
    position: relative;
    width: 100%;
    height: 100%; /* Full viewport height */
    /*background-color: var(--primary-color);*/
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
}

.back_button {
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
}

.back_button img {
    height: 24px;
    width: auto;
}

/* Scrollable Content Wrapper */
.scrollable_content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    /*box-sizing: border-box;*/
    /*height: calc(100vh - 40px); !* Full height minus padding for back button *!*/
/*    Hide scroll bar */
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.profile_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.username {
    color: var(--white-color);
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin: 15px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.user_level {
    color: var(--gold-color);
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0;
}

.progress_bar {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 20px;
    background-color: var(--secondary-color);
    border-radius: 10px;
    overflow: hidden;
    margin: 5px 0;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
}

.progress_bar_fill {
    height: 100%;
    background-color: var(--gold-color);
    transition: width 0.4s ease;
}

.experience_text {
    color: var(--gold-color);
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
}

/* Stats Section */
.stats_section {
    width: 100%;
    /*background-color: var(--primary-color);*/
    border-radius: 10px;
    /*margin-top: 20px;*/
    text-align: center;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.stats_title {
    color: var(--white-color);
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 15px;
    /*padding-top: 15px;*/
}

.stats_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.stat_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--tertiary-color);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.stat_label_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.stat_label {
    font-size: 14px;
    color: var(--white-color);
    font-weight: bold;
    margin-bottom: 5px;
}

.stat_icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

.stat_value {
    font-size: 18px;
    color: var(--gold-color);
    font-weight: bold;
}