/* Define variables */
:root {
    --overlay-margin-top: 6px;
    --overlay-margin-bottom: 20px;
    --overlay-margin-horizontal: 2px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    pointer-events: none; /* Remember to override this in the child elements */
}

.top_panel_overlay {
    position: absolute;
    /* Center horizontally */
    left: 50%;
    transform: translateX(-50%);
    pointer-events: auto;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 9px 7px 12px 7px;
    margin-top: var(--overlay-margin-top);
}

.camera_mode_button_active {
    position: absolute;
    pointer-events: auto;
    top: 0;
    right: 0;
    border-radius: 8px;
    background-color: var(--primary-color);
    margin-top: var(--overlay-margin-top);
    margin-right: var(--overlay-margin-horizontal);
}

.camera_mode_button_inactive {
    position: absolute;
    pointer-events: auto;
    top: 0;
    right: 0;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-top: var(--overlay-margin-top);
    margin-right: var(--overlay-margin-horizontal);
}

.joystick_mode_button_active {
    position: absolute;
    pointer-events: auto;
    top: 0;
    right: 0;
    border-radius: 8px;
    background-color: var(--primary-color);
    margin-top: var(--overlay-margin-top);
    margin-right: var(--overlay-margin-horizontal);
}

.joystick_mode_button_inactive {
    position: absolute;
    pointer-events: auto;
    top: 0;
    right: 0;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-top: var(--overlay-margin-top);
    margin-right: var(--overlay-margin-horizontal);
}

.joystick_controls {
    position: absolute;
    pointer-events: auto;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin-bottom: var(--overlay-margin-bottom);
}

.joystick_control_button {
    border-radius: 8px;
    background-color: var(--primary-color);
    margin-right: 4px;
    padding-left: 16px;
    padding-right: 16px;
}

.user_avatar_container {
    position: absolute;
    pointer-events: auto;
    top: 0;
    left: 0;
    margin-top: var(--overlay-margin-top);
    margin-left: var(--overlay-margin-horizontal);
}

/* Center bottom */
.recenter_button {
    position: absolute;
    pointer-events: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    background-color: var(--primary-color);
    margin-bottom: var(--overlay-margin-bottom);
    padding-left: 16px;
    padding-right: 16px;
}

.recenter_button_text {
    pointer-events: auto;
    font-size: 16px;
    color: white;
    font-weight: 600;
    margin: 0;
}