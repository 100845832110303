.friends_page {
    position: relative;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.friends_header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    background-color: var(--primary-color);
    box-shadow: 0 4px 8px rgba(var(--secondary-color-triplet), 0.2);
}

.friends_title {
    margin: 0 auto;
    color: var(--white-color);
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.back_button {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.back_button img {
    height: 24px;
    width: auto;
}

.add_friend_button {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.add_friend_button img {
    height: 24px;
    width: auto;
}

.friends_list {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px;
}

.friend_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 10px;
    background-color: var(--white-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.friend_card_username_avatar {
    display: flex;
    align-items: center;
}

.friend_card_username {
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
}

.friend_card_avatar_container {
    position: relative;
    display: inline-block;
}

.friend_card_online_status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--avatar-offline-color);
    position: absolute;
    right: 3px;
    bottom: 3px;
}

.friend_actions_container {
    display: flex;
    align-items: center;
}

.friend_action_button {
    cursor: pointer;
    margin-left: 10px;
    padding: 5px;
    border-radius: 8px;
}

.friend_action_button img {
    height: 24px;
    width: auto;
    margin: 0;
}

.share_friend_link_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    background-color: var(--white-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: fit-content;
    align-self: center;
    padding: 10px;
}

.share_friend_link_image {
    height: 24px;
    width: auto;
    margin-left: 10px;
}

.share_friend_link_text {
    font-size: 18px;
    font-weight: bold;
}