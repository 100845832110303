@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.pageBg {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #0028ff, #00abff);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
  }
  
  .animatedImage {
    position: absolute;
    top: 0px;
    width: 100%;
    animation: moveUp 30s linear infinite;
    opacity: 0.033;
  }
  
  @keyframes moveUp {
    0% {
      top: 0px;
    }
    100% {
      top: calc(-400vw + 100vh);
    }
  }
  
  .pageContent {
    width: calc(100% - 40px);
    min-height: 300px;
    z-index: 2;
    position: relative;
    padding: 20px;
    overflow: hidden;
  }
  
  .pageTitle {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 42px;
    text-shadow: 0px 3px 11px rgba(0, 68, 158, 0.39);
    letter-spacing: 1px;
    margin: 10px 0 15px 0;
  }
  
  .referralPanel {
    width: 100%;
    aspect-ratio: 406/218;
    margin-bottom: 20px;
    position: relative;
    background-size: cover;
    background-position: center;
  }
  
  .buttonContainer {
    position: absolute;
    width: calc(100% - 30px);
    aspect-ratio: 406/80;
    bottom: 0;
    padding: 0 15px;
  }
  
  .refBtn {
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 22px;
    width: calc(50% - 10px);
    float: left;
    aspect-ratio: 181 / 50;
    border-radius: 6px;
    border: 2px solid rgba(239, 132, 35, 0.34);
    background-color: white;
    box-shadow: 0px 3px 10px 0px rgb(210 113 26);
    color: #ff8e30;
    padding-top: 2%;
  }
  
  .last {
    margin-left: 20px;
  }
  
  .questPanel {
    padding: 15px;
    width: calc(100% - 30px);
    min-height: 100px;
    overflow: hidden;
    border-radius: 8px;
    border: 2px solid #05e4ff;
    background: linear-gradient(0deg, #00aefff0, #00d8fff0), url('../../assets/images/backgrounds/background-ani.png');
    background-size: 100%;
    box-shadow: 0px 10px 15px 0px rgba(0, 211, 255, 0.26);
    background-position: center top;
    margin-top: 0px;
  }
  
  .title {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 32px;
    text-shadow: 0px 3px 11px rgba(0, 68, 158, 0.39);
    letter-spacing: 1px;
    margin: 10px 0 15px 0;
  }

.back_button {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.back_button img {
    height: 24px;
    width: auto;
}

