@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

.questItem {
  height: 160px;
  background-image: url('../../../assets/images/backgrounds/blue_tile.png');
  background-size: cover;
  padding: 18px;
  display: flex;
  flex-direction: column;
  color: white;
}

.questTitle {
  font-family: 'Luckiest Guy', cursive;
  font-size: 32px;
  margin: 0;
}

.questDescription {
  font-family: 'Luckiest Guy', cursive;
  font-size: 16px;
  margin: 0;
}

.questSpacer {
  flex-grow: 1;
}

.questFooter {
  display: flex;
  align-items: center;
}

.questReward {
  font-family: 'Luckiest Guy', cursive;
  font-size: 24px;
  color: rgb(251, 229, 53);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}